import React, { createContext, useContext, useMemo, useState } from "react"

import { EMode, TSetState } from "@types"

interface IModeContextValue {
  mode: EMode
  isDemo: boolean
  setMode: TSetState<EMode>
}

interface IModeContextProvider {
  children: React.ReactNode
}

const ModeContext = createContext({} as IModeContextValue)
export const useModeContext = (): IModeContextValue => useContext(ModeContext)

export const ModeContextProvider: React.FC<IModeContextProvider> = ({ children }) => {
  const [mode, setMode] = useState(EMode.STANDBY)

  const contextValue = useMemo((): IModeContextValue => ({ mode, isDemo: mode === EMode.DEMO, setMode }), [mode])

  return <ModeContext.Provider value={contextValue}>{children}</ModeContext.Provider>
}
