import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"

import { DemoScript, WaveformLauncher } from "@utils"

import { initialStations } from "@constants"
import { events1, quakes1, signals1, ai1 } from "@constants"
import { events2, quakes2, signals2, ai2 } from "@constants"
import { stdbyAI, stdbyQuakes, stdbySignals } from "@constants"

import { EDemoSpeed, IAIData, IChartData, IMessage, IQuake, IStation } from "@types"
import { useModeContext } from "./_ModeContext"

const demoData = [
  { quakes: quakes1, events: events1, signals: signals1, ai: ai1 },
  { quakes: quakes2, events: events2, signals: signals2, ai: ai2 },
]

interface IDemoScriptContextValue {
  demoScript: DemoScript
  waveformLauncher: WaveformLauncher
  stations: IStation[]
  quakes: IQuake[]
  signals: Record<string, IChartData[]>
  AIData: IAIData
  handleDemoSelect: (selection: number) => void
}

interface IDemoScriptContextProvider {
  children: React.ReactNode
}

const DemoScriptContext = createContext({} as IDemoScriptContextValue)
export const useDemoScriptContext = (): IDemoScriptContextValue => useContext(DemoScriptContext)

export const DemoScriptContextProvider: React.FC<IDemoScriptContextProvider> = ({ children }) => {
  const { isDemo } = useModeContext()

  const demoScript = useMemo(() => new DemoScript(events1 as IMessage[]), [])
  const waveformLauncher = useMemo(() => new WaveformLauncher(), [])

  const [stations] = useState(initialStations)
  const [quakes, setQuakes] = useState(stdbyQuakes)
  const [signals, setSignals] = useState(stdbySignals)
  const [AIData, setAIData] = useState(stdbyAI)

  const setStandbyData = useCallback(() => {
    demoScript.stop()

    setQuakes(stdbyQuakes)
    setSignals(stdbySignals)
    setAIData(stdbyAI)
  }, [])

  const handleDemoSelect = useCallback((selection: number) => {
    demoScript.stop()

    const { quakes, events, signals, ai } = demoData[selection]
    demoScript.setEvents(events as IMessage[])

    setQuakes(quakes)
    setSignals(signals)
    setAIData(ai)
  }, [])

  const contextValue = useMemo(
    (): IDemoScriptContextValue => ({
      demoScript,
      waveformLauncher,
      stations,
      quakes,
      handleDemoSelect,
      signals,
      AIData,
    }),
    [stations, quakes, signals]
  )

  useEffect(() => {
    if (isDemo) {
      waveformLauncher.stop()
      handleDemoSelect(0)
    } else {
      demoScript.setSpeed(EDemoSpeed.X1)
      setStandbyData()
      waveformLauncher.start()
    }
  }, [isDemo])

  return <DemoScriptContext.Provider value={contextValue}>{children}</DemoScriptContext.Provider>
}
