import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react"

import { RU, EN, KG } from "@assets"
import { ELang, ILocale } from "@types"
import { TSetState } from "@types"

interface ILocalizationContextValue {
  l: ILocale
  setLocale: TSetState<ELang>
}

interface ILocalizationContextProvider {
  children: React.ReactNode
}

const LocalizationContext = createContext({} as ILocalizationContextValue)
export const useLocalizationContext = (): ILocalizationContextValue => useContext(LocalizationContext)

export const LocalizationContextProvider: React.FC<ILocalizationContextProvider> = ({ children }) => {
  const isFirstRender = useRef(true)

  const storedLocale = localStorage.getItem("language")

  const [locale, setLocale] = useState((storedLocale as ELang) ?? ELang.RU)

  const l = useMemo((): ILocale => {
    switch (locale) {
      case ELang.RU:
        return RU as ILocale
      case ELang.EN:
        return EN as ILocale
      case ELang.KG:
        return KG as ILocale
      default:
        return RU as ILocale
    }
  }, [locale])

  const lContextValue = useMemo((): ILocalizationContextValue => ({ l, setLocale }), [l])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    localStorage.setItem("language", locale)
    window.location.reload()
  }, [locale])

  return <LocalizationContext.Provider value={lContextValue}>{children}</LocalizationContext.Provider>
}
